
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import { watch } from 'vue-demi';
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  props: {
    sid: String,
    modelValue: Boolean,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props) {
    const model = CloudFun.current?.model

    const grid = ref<any>();
    const gridOptions: GridOptions = {
      title: '聘僱資料',
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      showFooter: true,
      toolbarConfig: {
        refresh: true,
      },
      columns: [
        { field: 'Department.Name', title: '部門', showOverflow: true, sortable: true, resizable: true },
        { field: 'Position.Name', title: '職務', showOverflow: true, sortable: true, resizable: true },
        { field: 'Title', title: '職稱', showOverflow: true, sortable: true, resizable: true },
        { field: 'Level', title: '職等', showOverflow: true, sortable: true, resizable: true },        
        { field: "Status", title: "狀態", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.EmploymentStatus).find(e => e.Value === cellValue)?.Name : undefined },
      ],
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
      modalConfig: { width: "50%", height: "450", showFooter: true },
    }

    const formOptions: VxeFormProps = {
      titleAlign: 'right',
      items: [
        {
          field: 'DepartmentId',
          title: '部門',
          span: 12,
          slots: { default: "column-departmentId" }
        },
        {
          field: 'PositionId',
          title: '職務',
          span: 12,
          slots: { default: "column-positionId" }
        },
        { field: 'Title', title: '職稱', span: 12, itemRender: { name: '$input' } },
        { field: 'Level', title: '職等', span: 12, itemRender: { name: '$input' } },
        {
          field: 'StartDate',
          title: '到職日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'EndDate',
          title: '離職日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        { field: "Status", title: "狀態", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.EmploymentStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
      ],
      rules:{
        DepartmentId: [{ required: true }],
        PositionId: [{ required: true }],
        Status: [{ required: true }],
        Title: [{ type: 'string', required: true }],
      }
    }


    const initData = (row: any, callback: any) => {
      row.EmployeeId = props.sid;
      row.Status = 0;
      row.PositionId = 0;
      row.DepartmentId = 0;
      callback();
    }

    const positionSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: async(value: any) => await model!.dispatch("position/find", value), // eslint-disable-line
        query: async(params: any) => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return await model!.dispatch("position/query", params) // eslint-disable-line
        }
      }
    };

     const departmentSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ContactPerson",
          title: "聯絡人",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: async(value: any) => await model!.dispatch("department/find", value), // eslint-disable-line
        query: async(params: any) => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return await model!.dispatch("department/query", params) // eslint-disable-line
        }
      }
    };

    return {
      grid,
      initData,
      gridOptions,
      formOptions,
      departmentSelectorOptions,
      positionSelectorOptions,
    }
  },
  methods: {
    refresh() {      
      (this.$refs.grid as any).refresh();
    },
    async onGridEdit (row: any, callback: any) {
      // const entity = row.Id ? await this.$model.dispatch('documentRecord/find', row.Id) : undefined
      // Object.assign(row, entity)
      // const statuses = (Object.values(this.$model.enums.DocumentStatus) as any[]);
      // row.BeforeStatusName = statuses.find(e => e.Value === row.BeforeStatus).Name;
      // row.AfterStatusName = statuses.find(e => e.Value === row.AfterStatus).Name;
      //   if (entity) {
      //     entity.Xml.Time = new Date(entity.Xml.Time)
      //   }
      callback()
    },
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
