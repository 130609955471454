
import CloudFun, { defineComponent, ref, reactive, Condition, Operator } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import EmploymentTable from "@/components/employee/EmploymentTable.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    FileUploader,
    EmploymentTable,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});
    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("EmployeeCreate");
    const canUpdate = checkPermissions("EmployeeUpdate");
    const canDelete = checkPermissions("EmployeeDelete");

    const gridOptions: GridOptions = {
      id: "user",
      title: "員工",
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      multiselect: false,
      canDelete: canDelete,
      canUpdate: canUpdate,
      canCreate: canCreate,
      printConfig: {
        sheetName: "員工清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "角色清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Account", title: "帳號", showHeaderOverflow: true, showOverflow: true },
        { field: "Email", title: "Email", showHeaderOverflow: true, showOverflow: true },
        { field: "Status", title: "狀態", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.UserStatus).find(e => e.Value === cellValue)?.Name : undefined },
        {
          title: '聘僱資料',
          width: '100',
          slots: { default: "employment" }, align: 'center'
        },
        { field: "Online", title: "是否在線", showHeaderOverflow: true, showOverflow: true, resizable: false, width: 100, cellRender: { name: "$switch", props: { openLabel: '上線', closeLabel: '離線', disabled: true } } },
      ],
      promises: {
        query: model ? (params) => model.dispatch("employee/query", params) : undefined,
        queryAll: model ? () => model.dispatch("employee/query") : undefined,
        save: model ? (params) => model.dispatch("employee/save", params) : undefined,
      },
      modalConfig: { width: 800, showFooter: true, fullscreen: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: "Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "名稱" } } },
        { field: "Status", title: "帳號狀態", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.UserStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "Person.EnglishName", title: "英文名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "英文名稱" } } },
        { field: "Account", title: "帳號", span: 12, itemRender: { name: "$input", props: { placeholder: "帳號" } } },
        { field: "Password", title: "密碼", span: 12, itemRender: { name: "$input", props: { type: "password", placeholder: "密碼", autocomplete: "new-password" } } },
        { field: "Phone", title: "電話號碼", span: 12, itemRender: { name: "$input", props: { placeholder: "電話號碼" } } },
        { field: "MobilePhone", title: "行動電話", span: 12, itemRender: { name: "$input", props: { placeholder: "行動電話" } } },
        { field: "Email", title: "Email", span: 12, itemRender: { name: "$input", props: { placeholder: "Email" } } },
        { field: "Number", title: "工號", span: 12, itemRender: { name: "$input", props: { placeholder: "工號" } } },
        { field: "EmployeeStatus", title: "員工狀態", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.EmployeeStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "LaborNumber", title: "勞保編號", span: 12, itemRender: { name: "$input", props: { placeholder: "勞保編號" } } },
        { field: "InsuranceDate", title: "加保日期", span: 12, itemRender: { name: "$input", props: { placeholder: "加保日期", type: "date" } } },
        { field: "SuspensionDate", title: "退保日期", span: 12, itemRender: { name: "$input", props: { placeholder: "退保日期", type: "date" } } },
        { field: "ResidencePhone", title: "戶籍電話", span: 12, itemRender: { name: "$input", props: { placeholder: "戶籍電話" } } },
        { field: "ResidenceAddress", title: "戶籍地址", span: 24, itemRender: { name: "$input", props: { placeholder: "戶籍地址" } } },
        { field: "DepartmentId", title: "部門", span: 12, slots: { default: "column-department" } },
        { field: "ManagerNo", title: "直屬主管", span: 12, slots: { default: "column-submitter" } },
        // { field: "RoleIds", title: "角色", span: 24, slots: { default: "column-role-ids" } },
        { field: "Photo.Uri", title: "頭像", span: 24, slots: { default: "column-photo-and-remark" } },
        { field: "SignaturePhoto.Uri", title: "中文簽名", span: 12, slots: { default: "column-SignaturePhoto" } },
        { field: "SignatureEnglishPhoto.Uri", title: "英文簽名", span: 12, slots: { default: "column-SignatureEnglishPhoto" } },
        { field: "Online", title: "是否在線", span: 12, itemRender: { name: "$switch", props: { openLabel: '上線', closeLabel: '離線', disabled: true } } },
        { field: "LoginIp", title: "IP位址", span: 12, itemRender: { name: "$input", props: { placeholder: "IP位址", disabled: true } } },
        { field: "LoginTime", title: "登入時間", span: 12, itemRender: { name: "$input", props: { type: "datetime", placeholder: "登入時間", disabled: true } } },
        { field: "LogoutTime", title: "登出時間", span: 12, itemRender: { name: "$input", props: { type: "datetime", placeholder: "登出時間", disabled: true } } },
      ],
      rules: {
        Name: [{ required: true }],
        Email: [{ required: true, message: "Email格式錯誤", pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }],
        Account: [{ required: true }],
        Password: [{
          required: false,
          validator: (params) => {
            if (params.itemValue) {
              const regex = new RegExp("^((?=.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)");
              if (!regex.test(params.itemValue)) return new Error("須8碼以上含大小寫英文、數字");
            } else if (!params.data.Id) return new Error("新員工須設定密碼");
          },
        }],
        Phone: [
          {
            type: "string",
            pattern: new RegExp(
              "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
            ),
          },
        ],
        MobilePhone: [
          {
            type: "string",
            pattern: new RegExp(
              "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
            ),
          },
        ],
        ResidencePhone: [
          {
            type: "string",
            pattern: new RegExp(
              "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
            ),
          },
        ],
        // RoleIds: [{ required: true, validator: (params) => { if (!params.itemValue?.length) return new Error("須選擇至少一個以上的角色"); } }],
      }
    };

    const employmentGrid = ref<any>()
    const employmentModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition("Position.DataMode", Operator.Equal, 0).and("Department.DataMode", Operator.Equal, 0)
              .and("EmployeeId", Operator.Equal, employmentModal.selectedRow.Id.toString())
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0) {
              params.sortings = [{ column: "CreatedTime", order: 1 }];
            }
            // var selectedStatus = employmentGrid?.value.getSelectedStatus();
            // if (selectedStatus != null) {
            //   params.condition.and("Status", Operator.Equal, selectedStatus)
            // }
            return model.dispatch("employment/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('employment/query') : undefined,
          save: model ? (params) => model.dispatch('employment/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        employmentModal.visible = true;
        employmentModal.selectedRow = row;
        if (employmentGrid.value) employmentGrid.value.refresh();
      }
    })

    const addNewRow = (row: any, callback: any) => {
      row.Status = 0;
      row.Online = false;
      row.EmployeeStatus = 0;
      row.Person = { Reamrk: '' };
      callback();
    }

    const departmentSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Number",
          title: "編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("department/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("department/query", params) // eslint-disable-line
        }
      }
    };

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Number",
      columns: [
        {
          field: "Person.Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => {
          return model!.dispatch("employee/findByNo", value)
        }, // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/file`,
      canCreate,
      canUpdate,
      employmentGrid,
      employmentModal,
      addNewRow,
      departmentSelectorOptions,
      userSelectorOptions
    };
  },
  methods: {
    hideBatchDropDown () {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter (current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    }
  }
});
